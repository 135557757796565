<template>
  <div>
    <div class="block">
      <b-field v-for="question in showCategory.category.questions" :key="question.question_id" :label="question.question_name">
        <ul>
          <li>
        <div v-for="answer in question.answers" :key="answer.answer_id">
          <b-radio v-model="answers[question.question_id]" :name="question.question_id" :native-value="answer.answer_id" required @input="refresh()">{{ answer.answer_name }}</b-radio>
        </div>
          </li>
        </ul>
      </b-field>
      <b-field label="Pliki przesłane do zgłoszenia">
        <b-upload v-model="dropFiles" multiple drag-drop expanded @input="$emit('change', dropFiles)">
          <section class="section">
            <div class="content has-text-centered">
              <p>
                <b-icon icon="upload" size="is-large"></b-icon>
              </p>
              <p>Kliknij w pole by wybrać pliki lub przeciągnij i upuść je tutaj</p>
            </div>
          </section>
        </b-upload>
      </b-field>
      <div class="tags">
        <span v-for="(file, index) in dropFiles" :key="index" class="tag is-primary">
          {{ file.name }}
          <button class="delete is-small" type="button" @click="deleteDropFile(index)"></button>
        </span>
      </div>
      <b-field label="Opis / Dodatkowe uwagi">
        <b-input v-model="description" type="textarea" @input="refresh"/>
      </b-field>
    </div>
  </div>
</template>

<script>
import {showCategory} from "@/graphql/queries"

export default {
  name: "Categories",
  props: ["id", "accept", "multiple", "disabled"],
  apollo: {
    showCategory: {
      query: showCategory,
      variables() {
        return {
          category_id: this.id
        }
      }
    }
  },
  data() {
    return {
      showCategory: {
        category: {
          questions: [
            {
              question_id: null,
              question_name: null,
              answers: [
                {
                  answer_id: null,
                  answer_name: null
                }
              ]
            }
          ]
        }
      },
      answers: {},
      dropFiles: [],
      description: "",
    }
  },
  watch: {
    id() {
      this.answers = {}
    }
  },
  methods: {
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1)
    },
    refresh() {
      this.$emit("input", {
        answers: this.answers,
        description: this.description
      })
    }
  }
}
</script>

<style scoped></style>
