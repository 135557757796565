import gql from "graphql-tag"

export const showCategory = gql`
  query showCategory($category_id: ID!) {
    showCategory(category_id: $category_id) {
      category {
        category_id
        category_name
        questions {
          question_id
          question_name
          answers {
            answer_id
            answer_name
          }
        }
      }
    }
  }
`;

export const showCategories = gql`
  query {
    showCategories {
      categories {
        category_id
        category_name
      }
    }
  }
`;

