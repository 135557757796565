<template>
  <div>
    <form @submit.prevent="$refs.recaptcha.execute()">
      <div class="block">
        <b-field label="Podaj imię:">
          <b-input v-model="person.firstName" placeholder="Imię" required />
        </b-field>
        <b-field label="Podaj nazwisko:">
          <b-input v-model="person.lastName" placeholder="Nazwisko" required />
        </b-field>
        <b-field label="Podaj adres e-mail:">
          <b-input v-model="person.email" type="email" placeholder="Adres e-mail" required />
        </b-field>
        <b-field label="Podaj numer telefonu (opcjonalne):">
          <b-input v-model="person.number" placeholder="Numer telefonu" />
        </b-field>
        <b-field v-if="person.firstName && person.lastName && person.email" label="Wybierz kategorię:">
          <ul>
            <li>
              <div v-for="category in showCategories.categories" :key="category.category_id">
                <b-radio v-model="selected_category" name="selected_category" :native-value="category.category_id" required>
                  {{ category.category_name }}
                </b-radio>
              </div>
            </li>
          </ul>
        </b-field>
        <categories v-if="selected_category" :id="selected_category" @input="changeData" :multiple="true" :disabled="false" accept="" @change="uploadFilesChange" />
      </div>
      <div>
        <div class="level-right">
          <vue-recaptcha ref="recaptcha" @verify="onCaptchaVerified" @expired="onCaptchaExpired" size="invisible" :sitekey="$reCAPTCHA" :loadRecaptchaScript="true" />
        </div>
        <div class="level-right">
          <b-button type="is-primary" icon-left="email-send" native-type="submit" :disabled="formSubmitting">Wyślij zgłoszenie</b-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import VueRecaptcha from "vue-recaptcha"
  import {email, required} from "vuelidate/lib/validators"
  import {showCategories} from "@/graphql/queries"
  import {uploadForm} from "@/graphql/mutations"
  import Categories from "@/components/categories/Categories"

export default {
  name: "Stage1",
  components: {
    VueRecaptcha,
    Categories
  },
  data() {
    return {
      formSubmitting: false,
      showCategories: null,
      selected_category: this.category_id,
      answers: null,
      description: null,
      files: null, 
      person: {
        firstName: null,
        lastName: null,
        email: null,
        number: null
      }
    };
  },
  validations() {
    return {
      person: {
        firstName: { required },
        lastName: { required },
        email: { required, email }
      }
    };
  },
  apollo: {
    showCategories: showCategories
  },
  watch: {
    category(value) {
      this.$emit("response", value);
      this.answers = "";
    }
  },
  methods: {
  async onCaptchaVerified(recaptchatoken) {
    console.log("Files before mutation:", this.files);
    if (this.$t("success-send")) {
      this.formSubmitting = true;

      this.$refs.recaptcha.reset();
      await this.$apollo.mutate({
        mutation: uploadForm,
        variables: {
          answers: this.answers,
          description: this.description,
          file: this.files && this.files.length > 0 ? await Promise.all(
            this.files.map(async (file) => ({
              data: await this.readFileAsBase64(file),
              mimetype: file.type,
              filename: file.name,
            }))
          ) : [],
          category_id: this.selected_category,
          person: {
            firstName: this.person.firstName,
            lastName: this.person.lastName,
            number: this.person.number,
            email: this.person.email
          },
          recaptchatoken
        },
        refetchQueries: ["uploadForm"]
      });
      this.$swal("Politechnika Poznańska", this.$t("success-send"), "success");
    } else {
      this.$swal("Politechnika Poznańska", this.$t("error-send"), "error");
    }
  },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    changeData(response) {
      this.answers = Object.fromEntries(Object.entries(response.answers).filter(([key]) => key !== "__ob__"));
      this.description = response.description;
    },
    uploadFilesChange(dropFiles) {
      this.files = dropFiles;
    },
    readFileAsBase64(file) {
      return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = reject;
      reader.readAsDataURL(file);
      });
    }
  }
}
</script>

<style scoped></style>