import { render, staticRenderFns } from "./Stage1.vue?vue&type=template&id=20a5c094&scoped=true"
import script from "./Stage1.vue?vue&type=script&lang=js"
export * from "./Stage1.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20a5c094",
  null
  
)

export default component.exports